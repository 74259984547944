import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { Layout } from '../components/common'
import { MetaData } from '../components/common/meta'
import { tagRouteBaseUrl } from '../constants/strings'
import Filter from '../components/BlogIndex/Filter'
import BannerSection from '../components/BlogIndex/BannerSection'
import Posts from '../components/BlogIndex/Posts'
import TrendingTopics from '../components/BlogIndex/TrendingTopics'

/**
* Tag page (/tag/:slug)
*
* Loads all posts for the requested tag incl. pagination.
*
*/
const Tag = ({ data, location, pageContext }) => {
    // const tag = data.ghostTag
    const wrapper = useRef(null);

    const posts = data.allGhostPost.edges
    const tags = data.tags.edges;
    const featuredPost = data.allFeaturedPost.edges
    const latestPost = data.allLatestPost.edges; // latest 5 posts without featured flag turned on
    const trendingTags = data.trendingTag.edges;
    const meta = data.meta.nodes[0];

    const { slug } = pageContext;

    useEffect(() => {
        if (location.pathname.includes('page')) {
            let elmnt = wrapper.current;

            var headerOffset = 80;
            var elementPosition = elmnt.getBoundingClientRect().top;
            var offsetPosition = elementPosition - headerOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        }
    }, [wrapper]);

    return (
        <>
            <MetaData
                // data={data}
                location={location}
                // type="series"
                title={meta?.meta_title}
                description={meta?.meta_description}
            />
            <Layout>
                <BannerSection featuredPost={
                    // we are showing the latest posts if no featured posts are marked in the section
                    featuredPost.length > 0 ?
                        featuredPost.length < 5 ?
                            featuredPost.concat(latestPost.slice(0, latestPost.length - featuredPost.length))
                            : featuredPost
                        : latestPost
                }
                />
                <div ref={wrapper} >
                    <Filter tags={tags} slug={slug} />
                </div>
                <Posts posts={posts} pageContext={pageContext} indexRoute={`${tagRouteBaseUrl}/${slug}`} />
                <TrendingTopics trendingTags={trendingTags}/>
            </Layout>
        </>
    )
}

Tag.propTypes = {
    data: PropTypes.shape({
        ghostTag: PropTypes.shape({
            name: PropTypes.string.isRequired,
            description: PropTypes.string,
        }),
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default Tag

export const pageQuery = graphql`
    query GhostTagQuery($slug: String!, $limit: Int!, $skip: Int!) {
        ghostTag(slug: { eq: $slug }) {
            ...GhostTagFields
        }
        allGhostPost(
            sort: { order: DESC, fields: [published_at] },
            filter: {tags: {elemMatch: {slug: {eq: $slug}}}},
            limit: $limit,
            skip: $skip
        ) {
            edges {
                node {
                ...GhostPostFields
                }
            }
        }

    allFeaturedPost:allGhostPost(
        limit: 5,
        filter:{
            featured:{eq: true}
        	tags:{elemMatch:{slug:{eq:$slug}}}
        }
        sort: { order: DESC, fields: [published_at] },
    ) {
        edges {
                node {
                    ...GhostPostFields
                }
            }
        }

    tags: allGhostTag(
        filter:{
            visibility:{ eq: "public" }
            slug:{ ne: "opinion" }
        }
        sort: { order: ASC, fields: name }
        ) {
            edges {
                node {
                    slug
                    name
                }
            }
        }

    allLatestPost: allGhostPost(
        limit: 5,
        filter:{
            featured:{eq: false}
            tags:{elemMatch:{slug:{eq:$slug}}}
        }
  	    sort: { order: DESC, fields: [published_at] }
        ){
         edges{
            node{
                ...GhostPostFields
            }
        }
    }

    trendingTag: allGhostTag(
        limit: 10,
  	    sort: { order: DESC, fields: [postCount] }
        filter:{
            visibility:{ eq: "public" }
    	    accent_color:{eq: "#FF0000"}
        }
        ){
            edges{
        node{
            name
            slug
        }
    }
  }

    meta: allGhostPage (
                filter: {
                    tags : { elemMatch : { name: { eq: "#blog" }}}
                }
            ){
                nodes {
                    meta_title
                    meta_description
        }
    }
}
`
